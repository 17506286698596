<template>
  <div class="container mx-auto mb-6 mt-4">
    <b-card>
      <div class="vx-row mx-4 my-4">
        <h6 class="mt-2 mb-2">
          Please select the interview date below
        </h6>

        <flat-pickr
          v-model="interview_date"
          :config="configInterviewPicker"
          class="w-full mx-auto"
          placeholder="Select interview date and time"
        />
      </div>

      <div class="vx-row mx-4 my-4">
        <h6
          v-if="filtered_slots.length > 0"
          class="mt-2 mb-2"
        >
          Select a time slot to reschedule this interview
        </h6>
        <v-select
          v-if="filtered_slots.length > 0"
          v-model="filtered_slots"
          placeholder="Choose a time slot to reschedule this interview"
          class="w-full"
          :options="application_statuses"
                    label="value"
        >
        </v-select>

        <h6
          v-if="filtered_slots.length === 0"
          class="mt-2 mb-2 text-warning"
        >
          Seems there are no time slots available for this date.
        </h6>
      </div>

      <div class="vx-row mx-auto">
        <div class="vx-col w-1/2 mx-auto">
          <b-button
            :disabled="
              interview_date &&
                interview_date.length > 1 &&
                interview_start_time.length > 1
                ? false
                : true
            "
            class="mt-4 mb-4 w-full shadow-lg"
            variant="warning"
            @click="rescheduleInterview"
          >Reschedule interview</b-button>
        </div>
      </div>

      <!-- <div v-if="filtered_slots.length === 0" class="vx-row">
        <h6>
          You cannot book for an interview right now. Please try again later
        </h6>
      </div> -->
    </b-card>
  </div>
</template>
<script>
import { format, sub } from 'date-fns'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    flatPickr,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  data() {
    return {
      duration: 0,
      show_btn: false,
      interview_time: '',
      interview_date: 'reschedule',
      filtered_slots: [],
      booked_slots: [],
      interview_date_time: {},
      all_available_slots: [],
      booking_id: '',
      start_time: '',
      end_time: '',
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
      },
      configInterviewPicker: {
        altInput: true,
        altFormat: 'F j, Y',
        enableTime: false,
        enableSeconds: true,
        inline: true,
        minDate: sub(new Date(), { days: 1 }),
      },

      interview_date: '',
      interview_start_time: '',
      interview_end_time: '',
      isLoading: true,
    }
  },

  computed: {},
  watch: {
    interview_date(val) {
      this.getSlots(val)
    },
  },
  created() {
    const { email } = this.$store.state.auth.ActiveUser
    this.booking_id = this.$route.params.id
    this.isLoading = true

    this.getSlots()
    if (this.interview_date.length === 0) {
      this.interview_date = format(new Date(), 'yyyy-MM-dd')
    }
  },
  methods: {
    getSlots(updated_interview_date = null) {
      let { interview_date } = this

      if (interview_date) {
        if (interview_date.length === 0) {
          interview_date = format(new Date(), 'yyyy-MM-dd')
        }
      } else {
        interview_date = format(new Date(), 'yyyy-MM-dd')
      }

      if (updated_interview_date !== null) {
        interview_date = updated_interview_date
      }

      // this.$http
      //   .get(`/api/available-slots/${this.booking_id}/${interview_date}`)
      //   .then(response => {
      //     const output = response.data.slots
      //     this.duration = response.data.duration
      //     const { success } = response.data
      //     const { email } = this.$store.state.auth.ActiveUser
      //     this.interview_date_time = response.data.interview_date_time
      //     this.interview_date = response.data.interview_date_time.date
      //     this.interview_start_time = response.data.interview_date_time.start_time
      //     this.interview_end_time = response.data.interview_date_time.end_time

      //     if (success) {
      //       if (
      //         email.toLowerCase()
      //         === response.data.applicant_email.toLowerCase()
      //       ) {
      //         this.filtered_slots = output.map(slot => ({
      //           text: slot,
      //           value: slot,
      //         }))

      //         this.isLoading = false
      //       } else {
      //         this.$toast(
      //           {
      //             component: this.$toastContent,
      //             props: {
      //               title: 'Schedule Interview',
      //               icon: 'AlertCircleIcon',
      //               text: "You don't have adequate permissions to access this section.",
      //               variant: 'danger',
      //             },
      //           },
      //           { timeout: this.$longestTimeout },
      //         )
      //       }
      //     } else {
      //       this.isLoading = false
      //     }
      //   })
      //   .catch(error => {})
    },
    rescheduleInterview() {
      const all_data = {
        booking_id: this.booking_id,
        interview_date_time: {
          date: this.interview_date,
          start_time: this.interview_start_time,
          end_time: '',
        },
        duration: this.duration,

        company: this.$store.state.auth.ActiveUser.company_name,
      }

      this.$http
        .put('/api/book', { data: all_data })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text: 'Interview time changed successfully.',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$router.push('/events-list')
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text: 'Interview scheduling failed.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    updateSelectedSlot(item) {
      this.interview_date = item.date
      this.start_time = item.start_time
      this.end_time = item.end_time
      this.interview_time = `${item.date} From ${item.start_time} to ${item.end_time}`
    },
  },
}
</script>

<style lang="scss"></style>
